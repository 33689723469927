import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter, DefaultRoute } from './utils/coreRouter';
import Landing from '../pages/Landing';

const Routes = ({ }) => (
    <CoreRouter>
        <DefaultRoute exact path="/" component={Landing} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
