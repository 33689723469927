import React, { useState } from 'react';
import ReactPlayer from 'react-player'
import './landing.scss';
import './responsive.scss';
import data from './data.json';
import pins from './pins.json';
import animations from './animations.json';
import videos from './videos.json';
import useWindowDimensions from './windowDimensions';
import parse from "html-react-parser";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-6G2QX1FJD2'
}

TagManager.initialize(tagManagerArgs)

const Landing = (props) => {
    const [selected, setSelected] = useState(-1);
    const [selectedPin, setSelectedPin] = useState(-1);
    const [selectedVideo, setSelectedVideo] = useState(-1);
    const [selectedSubVideo, setSelectedSubVideo] = useState(-1);
    const [showMenu, setShowMenu] = useState(false);
    const [footerText, setFooterText] = useState(null);
    const [zoom, setZoom] = useState(true);
    const { height, width } = useWindowDimensions();
    const mapSize = { desktop: 3500, mobile: 1800 };
    const isMobile = width <= 768;
    const isPortrait = width <= height;
    const style = {};
    const objectStyle = {};

    // UAT mode
    // if(new URLSearchParams(props.location.search).get("uat") === null){ return <p className='forbidden'>Sorry, the page is currently under maintenance.</p> }
    if(isPortrait && !isMobile){ return <p className='forbidden'><img style={{ maxWidth: '100%', maxHeight: '100%'}} src="/images/horizontalWarning-TE.jpg"/></p> }
    if(!isPortrait && isMobile){ return <p className='forbidden'><img style={{ maxWidth: '100%', maxHeight: '100%'}} src="/images/portraitWarning-TE.jpg"/></p> }

    const start = () => {
        setSelected(0)
        setZoom(false);
    }

    const select = (key) => {
        setShowMenu(false);
        setSelected(key);
        setSelectedPin(-1);

        const zoom = key in data && "zoom" in data[key] ? data[key].zoom : true;
        setZoom(zoom);
    }

    switch(true){
        case !zoom && isPortrait:                    // Portrait Full Map
            style.width = '250vw';
            style.height = '250vw';
            style.backgroundPositionX = "20vw";
            style.backgroundPositionY = "10vh";

            objectStyle.width = '250vw';
            objectStyle.height = '250vw';
            objectStyle.left = "20vw";
            objectStyle.top = "10vh";
            break;
        case selected in data && 'position' in data[selected] && isPortrait:          // Portrait Selected
            style.backgroundPositionX = data[selected].position.x / mapSize.desktop * mapSize.mobile;
            style.backgroundPositionY = data[selected].position.y / mapSize.desktop * mapSize.mobile;

            objectStyle.left = data[selected].position.x / mapSize.desktop * mapSize.mobile;
            objectStyle.top = data[selected].position.y / mapSize.desktop * mapSize.mobile;
            break;
        case isPortrait:                              // Portrait Landing & fallback
            style.left = "50%";
            style.backgroundPositionX = "5px";
            style.backgroundPositionY = "-220px";

            objectStyle.left = "5px";
            objectStyle.top = "-220px";
            break;
        case !zoom && !isPortrait:                    // Landscape Full Map
            style.width = '120vw';
            style.height = '120vw';
            style.backgroundPositionX = "34vw";
            style.backgroundPositionY = "25vh";
            style.left = "50%";

            objectStyle.width = '120vw';
            objectStyle.height = '120vw';
            objectStyle.left = "34vw";
            objectStyle.top = "25vh";
            break;
        case !(selected in data) && !isPortrait:      // Landscape Landing
            style.left = "50%";
            style.backgroundPositionX = "0px";
            style.backgroundPositionY = "-500px";

            objectStyle.left = "0px";
            objectStyle.top = "-500px";
            break;
        case selected in data && 'position' in data[selected] && !isPortrait:         // Landscape Selected
            style.backgroundPositionX = data[selected].position.x;
            style.backgroundPositionY = data[selected].position.y;

            objectStyle.left = data[selected].position.x;
            objectStyle.top = data[selected].position.y;
            break;
        case !isPortrait:
            break;
    }

    return (
        <div className='landing'>
            <div className={`sideMenu ${showMenu ? "active" : ""}`}>
                <div className='back' onClick={() => setShowMenu(false)}>
                    <img src="/images/back.png"/>
                </div>
                <div className='item'
                     style={{backgroundImage: `url(/images/home.png)`}}
                     onClick={() => select(-1)}>
                    首頁
                </div>
                {
                    data.map((section, key) => {
                        return <div key={section.id} className="item"
                                    style={{backgroundImage: `url(${section.icon})`}}
                                    onClick={() => select(key)}
                        >{section.title}</div>
                    })
                }
            </div>

            {selectedVideo in videos &&
                <div className='videoPopup'>
                    <div className={`videoContainer${ "videos" in videos[selectedVideo] && selectedSubVideo == -1 ? " list" : ""}`}>
                        {"videos" in videos[selectedVideo] && selectedSubVideo == -1 &&
                            <>
                                <img className="back" src="/images/back.png" onClick={() => setSelectedVideo(-1)}/>
                                <div className="videoSelector">
                                    {
                                        videos[selectedVideo].videos.map((video, key) => {
                                            return <img key={key} className="thumbnail" src={video.thumbnail} onClick={() => setSelectedSubVideo(key)}/>
                                        })
                                    }
                                </div>
                            </>
                        }

                        {"videos" in videos[selectedVideo] && selectedSubVideo in videos[selectedVideo]['videos'] &&
                            <>
                                <img className="back" src="/images/back.png" onClick={() => setSelectedSubVideo(-1)}/>
                                <ReactPlayer
                                    className='videoplayer'
                                    url={ videos[selectedVideo]['videos'][selectedSubVideo]['video']}
                                    width='100%'
                                    height='auto'
                                    controls={true}
                                    playing={true}
                                    config={
                                        {
                                            file: {
                                                attributes: {
                                                    autoPlay: true,
                                                    controlsList: 'nodownload'
                                                },
                                            }
                                        }
                                    }/>
                            </>
                        }

                        {"video" in videos[selectedVideo] &&
                            <>
                                <img className="back" src="/images/back.png" onClick={() => setSelectedVideo(-1)}/>
                                <ReactPlayer
                                    className='videoplayer'
                                    url={videos[selectedVideo].video}
                                    width='100%'
                                    height='auto'
                                    controls={true}
                                    playing={true}
                                    config={
                                        {
                                            file: {
                                                attributes: {
                                                    autoPlay: true,
                                                    controlsList: 'nodownload'
                                                },
                                            }
                                        }
                                    }/>
                            </>
                        }
                    </div>
                </div>
            }

            {selectedPin in pins &&
                <div className='popup'>
                    <div className='title'>{ pins[selectedPin].title && parse(pins[selectedPin].title) }</div>
                    <div className='buttons'>
                        <img src="/images/bubble_back.png" onClick={() => setSelectedPin(-1)}/>
                        <a href={pins[selectedPin].url} target="_blank" onClick={ () => setSelectedPin(-1)}>
                            <img src="/images/bubble_next.png"/>
                        </a>
                    </div>
                </div>
            }
            {/*<div className='dot'></div>*/}

            <div className='map' style={{ ...style }}>

                <div className='objects' style={{ ...objectStyle }}>
                    {
                        pins.map((pin, key) => {
                            return <div keyname={pin.id} onClick={() => setSelectedPin(key)} target="_blank" key={key} className="pin"
                                      style={{ left : pin.position.x, top: pin.position.y }}>
                                <img src={pin.icon}/>
                            </div>
                        })
                    }

                    {
                        animations.map((animation, key) => {
                            return <div className="animation" keyname={animation.id} key={animation.id} style={{
                                left : animation.position.x,
                                top: animation.position.y,
                                width: (animation.width / mapSize.desktop * 100)+"%",
                                height: (animation.height / mapSize.desktop * 100)+"%",
                                background: "url("+animation.image+") left top",
                                animation: "rotating "+animation.time+"s steps("+animation.frame+", end) infinite",
                                backgroundSize: "auto 100%",
                                transform: "translateX(-50%) translateY(-50%) scale("+animation.scale+")"
                            }}></div>
                        })
                    }

                    {
                        videos.map((video, key) => {
                            return <div className="video" keyname={video.id} key={video.id} onClick={() => {
                                if(videos[key].video) {
                                    setSelectedVideo(key)
                                }
                            }} style={{
                                left : video.position.x,
                                top: video.position.y,
                                width: (video.width / mapSize.desktop * 100)+"%",
                                height: (video.height / mapSize.desktop * 100)+"%",
                                background: "url("+video.image+") left top",
                                backgroundSize: "auto 100%",
                                transform: "translateX(-50%) translateY(-50%) scale("+video.scale+")"
                            }}></div>
                        })
                    }
                </div>
            </div>

            <div className={`sidebar${selected >= 0 ? " show" : ""}`}>
                <div className='menu'>
                    {isPortrait &&
                        <div className="item"
                             style={{backgroundImage: `url(/images/dots.png)`}}
                             onClick={() => setShowMenu(true)}>
                        </div>
                    }
                    <div className='item' onClick={() => select(-1)}
                         style={{backgroundImage: `url(/images/home.png)`}}>
                    </div>
                    {
                        data.map((section, key) => {
                            return <div key={section.id} className={`item${selected == key ? " active" : ""}`}
                                        style={{backgroundImage: `url(${section.icon})`}}
                                        onClick={() => select(key)}
                            ></div>
                        })
                    }
                </div>
                <div className='section'>
                    {selected in data ?
                        <div className="description">
                            <div className="title">{data[selected].title}</div>
                            {data[selected].content}
                        </div>
                        : null
                    }
                </div>
            </div>

            <div className={`button${selected < 0 ? "" : " hide"}`} onClick={start}>
                <img src="/images/start.gif"/>
            </div>

            <div className="copyright">
                ©2022. 教育局版權所有  |
                 <a href='#' style={{ color: 'inherit'}} onClick={ () => { setFooterText("政府會記錄使用者瀏覽「edb-hmsc-virtualexhibition.hk」的次數，但不會收集他們的個人識別資料。所收集的瀏覽次數記錄只會用於製作統計報告及調查電腦系統問題，以助政府改善「edb-hmsc-virtualexhibition.hk」的服務。")}}>私隱政策</a>  |
                 <a href='#' style={{color: 'inherit'}} onClick={ () => { setFooterText("展覽系統及設定建議<br/><br/>Microsoft Edge: Windows Edge 102 或以上<br/>Google Chrome: Windows Chrome 58 或以上<br/>Firefox: Windows Firefox 52 或以上<br/>Safari: Mac Safari (V10.1或以上)<br/>*因應微軟已於2022年6月15日停止對舊版IE提供安全性更新，若使用IE11瀏覽器，可能會出現無法正確顯示的情況，建議使用其他瀏覽器。<br/><br/>建議桌面版電腦瀏覽解析度：1920*1080。")}}>展覽系統及設定建議</a>  |
                 <a href='#' style={{color: 'inherit'}} onClick={ () => { setFooterText("鳴謝<br/><br/>香港大學牙科學院<br/>都會大學護理及健康學院<br/>葵青地區康健中心<br/>醫務衞生局")}}>鳴謝</a>
            </div>

            {footerText &&
                <div className="modal">
                    <p>
                        <img src="/images/close.png" onClick={() => { setFooterText(null) }}/>
                        { parse(footerText) }
                    </p>
                </div>
            }
        </div>
    );
};

export default Landing;

Landing.propTypes = {};
