import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);
